import { DefaultFacility } from "../types";

export function sortHallrentsAsc(hallrents: DefaultFacility["hallrents"]) {
  return [...hallrents]?.sort((a, b) => {
    if (a?.charge && b?.charge) {
      return a.charge - b.charge;
    }

    return 0;
  });
}
